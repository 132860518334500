import { regexConstants } from "../constants/regEx";
import moment from "moment";
import config from "../config";
import { globalPostService } from "./globalApiServices";
import Cookies from "universal-cookie";
import { trackLogin } from "./mixpanel";
const cookies = new Cookies();

export const checkEnvironment = () => {
  let env = "";
  switch (config.api.networkInterface) {
    case "https://api.sparta.aero/":
      env = "api";
      break;
    case "http://127.0.0.1:8000":
      env = "dev";
      break;
    default:
      env = "qa";
  }
  return env;
};
export const replaceEmptyNumber = (obj, fields) => {
  let payload = Object.assign({}, obj);
  fields.map((field) => {
      if(!payload[field]){
          payload = {
              ...payload,
              [field]:null
          }
      }
  });
  return payload;
}
export const validationMess=(keyName,limit,extra)=>{
  if(limit){
    return`${keyName} value should be allowed either N/A, NA, Unknown, UNK or 0 to 99999.99`
  }else if (extra === "999.99"){
    return`${keyName} value should be allowed either N/A, NA, Unknown, UNK or 0 to 999.99`
  }else if (extra === "999"){
    return`${keyName} value should be allowed either N/A, NA, Unknown, UNK or 0 to 999`
  }else {
    return`${keyName} value should be allowed either N/A, NA, Unknown, UNK or 0 to 99999`
  }
  
}

export const format_Commas = (number) => {
  if (number === '' || number === null || number === undefined) {
    return '';
  }
  const parts = number?.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}; 

export const commas_aster_thsnd=(number) =>{
  if (number === '' || number === null || number === undefined) {
    return '';
  }
  const parts = number?.toString().split('.');
  parts[0] = parseInt(parts[0], 10).toLocaleString();
  return parts.join('.');
  }
  
  export const Comma_format=(numbers) =>{
  return [numbers].map(number => commas_aster_thsnd(number));
  }


export function checkApiStatus(response) {
	 if(response.data?.statusCode){
    return (response?.data?.statusCode >= 200 && response?.data?.statusCode < 300);
  }else{
    return (response?.status >= 200 && response?.status < 300);
  }
}
export function checkLicenseStatus(response) {
  if (response && response.data && response.data.statusCode) {
    return (
      response.data.statusCode === 5000 ||
      response.data.statusCode === 5001 ||
      response.data.statusCode === 5002
    );
  }
}
export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}
export const getLocalStorageInfo = () => {
  return JSON.parse(localStorage.getItem("userInfo"));
};
export const permissionCheckFn = (args) => {
  let primaryKey = args.primaryKey ? args.primaryKey : "maintenance";
  if (
    getLocalStorageInfo() &&
    getLocalStorageInfo().user.permission &&
    getLocalStorageInfo().user.permission[primaryKey] &&
    getLocalStorageInfo().user.permission[primaryKey][args.secondaryKey] &&
    getLocalStorageInfo().user.permission[primaryKey][
      args.secondaryKey
    ].indexOf(args.keyIndex) !== -1
  ) {
    return true;
  } else {
    return false;
  }
};

export const permissionCheckFnforCamo = (args) => {
  let primaryKey = args.primaryKey ? args.primaryKey : "maintenance";
  if (
    getLocalStorageInfo() &&
    getLocalStorageInfo().user.permission &&
    getLocalStorageInfo().user.permission.camo &&
    Array.isArray(getLocalStorageInfo().user.permission.camo[primaryKey]) &&
    getLocalStorageInfo().user.permission.camo[primaryKey].includes(
      args.keyIndex
    ) &&
    args.userId === undefined
  ) {
    return true;
  } else if (
    getLocalStorageInfo() &&
    getLocalStorageInfo().user.permission &&
    getLocalStorageInfo().user.permission.camo &&
    Array.isArray(getLocalStorageInfo().user.permission.camo[primaryKey]) &&
    getLocalStorageInfo().user.permission.camo[primaryKey].includes(
      args.keyIndex
    ) &&
    args.keyIndex === "OD" &&
    args.userId !== undefined &&
    args.userId === getLocalStorageInfo().user.id
  ) {
    return true;
  } else {
    return false;
  }
};

export const getFileSize = (fileSize) => {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let l = 0,
    n = parseInt(fileSize, 10) || 0;
  while (n >= 1024 && ++l) n = n / 1024;
  return n.toFixed(2) + " " + units[l];
};

export const getPltConstantsList = (pltConstants, constantTypes) => {
  let constants = [];
  constantTypes.map((type) => {
    if (!pltConstants.find((item) => item.type === "type")) {
      constants = [...constants, type];
    }
  });
  return constants;
};

export const removeEmptyKey = (obj) => {
  for (let keyParam in obj) {
    if (
      obj[keyParam] === null ||
      obj[keyParam] === undefined ||
      obj[keyParam] === "" ||
      obj[keyParam].length === 0
    ) {
      delete obj[keyParam];
    }
  }
  return obj;
};

export const convertFilterObject = (filter = {}, filterManu = {}) => {
  for (let keyParam in filter) {
    if (
      typeof filter[keyParam] !== "string" &&
      Array.isArray(filter[keyParam])
    ) {
      filter = {
        ...filter,
        [keyParam]: filter[keyParam]
          .map((item) => item[filterManu[keyParam].valueKey])
          .join(","),
      };
    }
  }
  return filter;
};

export const downloadFileType = (fileData, fileName, fileExtension) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(fileData, fileName + "." + fileExtension);
  } else {
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName + "." + fileExtension);
    document.body.appendChild(link);
    link.click();
  }
};

export const localTimeFn = (timeData) => {
  let utcTime = timeData;
  let offset = moment().utcOffset();
  let localText = moment.utc(utcTime).utcOffset(offset).format("lll");
  return localText;
};
export const dateTransform = (date1, date2 = moment()) => {
  return moment(date2).diff(moment(date1), "days") + " Day(s)";
};

export const dateTransformAllSet = (date1, date2 = moment()) => {
  let b = moment(date1);
  let a = moment(date2);
  let years = a.diff(b, "year");
  b.add(years, "years");
  let months = a.diff(b, "months");
  b.add(months, "months");
  let days = a.diff(b, "days");
  let hours = a.diff(b, "hours");
  let mins = a.diff(b, "minutes");
  if (years > 0) {
    return years + " Year(s) ago";
  }
  if (months > 0) {
    return months + " Month(s) ago";
  }
  if (days > 0) {
    return days + " Day(s) ago";
  }
  if (hours > 0) {
    return hours + " Hour(s) ago";
  }
  if (mins > 0) {
    return mins + " Min(s) ago";
  } else {
    return "Just now";
  }
};

export const convertToYearMonth = (input) => {
  const monthMap = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  const [month, year] = input[0].split("-");
  return `${year}-${monthMap[month]}`;
};



export const getNumberFormat = () => {
  return {
    justification: "L",
    currency: false,
    commafy: true,
    shortFormat: false,
  };
};
export const getNumberDecimalFormat = () => {
  return {
    justification: "L",
    currency: false,
    commafy: true,
    shortFormat: false,
    precision: 2,
  };
};
// export const getCurrencyFormat = (currency='USD') => {
//   return {
//     justification: 'L',
//     currency: true,
//     precision: 2,
//     commafy: true,
//     shortFormat: true,
//     currencyIndicator:currency,
//     shortFormatMinValue:100000
//   }
// }

// export const eraseGlobalCookie = (name) => {
//   cookies.remove(name, { path: "/", domain: config.domain.authDomain });
// };
export const eraseGlobalCookie = (name) => {
  cookies.remove(name, {path: "/", domain: "sparta.aero"})
}
export const getGlobalCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export const setGlobalCookie = (name,value,days) => {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setDate(date.getDate() + days);
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + ';domain=sparta.aero'  + expires + "; path=/;";
}

export function authorizeAutoLogin(data = {}) {
  document.querySelector("#customLoader").style =
    "position: fixed;top: 0;left: 0;height: 100%;width: 100%;z-index: 9999;background: rgba(0,0,0,0.1);overflow: hidden;cursor: not-allowed;";
  globalPostService(`api/user-authorization/`, data).then((response) => {
    document.querySelector("#customLoader").style =
      "display: none;position: fixed;top: 0;left: 0;height: 100%;width: 100%;z-index: 9999;background: rgba(0,0,0,0.1);overflow: hidden;cursor: not-allowed;";
    if (checkApiStatus(response)) {
      let baseDomain = ".sparta.aero";
      let expireAfter = new Date();
      expireAfter.setDate(expireAfter.getDate() + 3);
      let cookieData = JSON.stringify({
        access: response.data.data.lessor_access_token,
        id: response.data.data.current_lessor.id,
        refresh: "",
        environment: checkEnvironment(),
      });
      let cookieName = JSON.stringify({
        name: response.data.data.user.name,
        designation: response.data.data.user.designation,
      });
      setGlobalCookie("userName", cookieName, 3);
      setGlobalCookie("lessorAccess", cookieData, 3);
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      userInfo = {
        ...userInfo,
        access: response.data.data.lessor_access_token,
        defaultLessor: response.data.data.current_lessor,
        user: response.data.data.user,
        lessor_list: response.data.data.lessor_list,
      };
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      setTimeout(() => {
				window.location.href = '/assets-listing';
			}, 2000);
    }
  });
}
