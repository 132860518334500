
import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { Button, Tooltip, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableCell, TableHead, TableRow, TableBody, TableSortLabel, Paper } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info';
import config from '../../../config'
import moment from 'moment';
import { dayMonthDateFormat } from "../../../constants";
import { Link } from "react-router-dom"
import { checkApiStatus } from '../../../utils';
import { EmptyCollection, PageLoader } from '../../../shared_elements';
import Pagination from '../../../shared_elements/components/Pagination';
import { globalGetService, globalExportService } from '../../../utils/globalApiServices';
import { getLocalStorageInfo, downloadFileType, convertToYearMonth } from "../../../utils"
import ExportMenu from '../../../shared_elements/components/ExportMenu';

function UnderlyingChartData({ onClose, assetData }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
    const [leaseStatusData, setleaseStatusData] = useState({ results: [], pagination: {} });
    const [onOffWingData, setOnOffWingData] = useState({ results: [], pagination: {} });
    const [svData, setsvData] = useState({ results: [], pagination: {} });

    const statusOption = [{ label: "On Lease", value: 1 }, { label: "Off Lease", value: 2 }, { label: "On Ground", value: 3 }, { label: "Written Off / Sold / Part out", value: 4 }, { label: "Archive", value: 5 }]
    const OnOffWingOption = [{ label: "In Shop Serviceable", value: 2 }, { label: "In Shop UnServiceable", value: 3 }, { label: "Off Wing Serviceable", value: 4 }, { label: "Off Wing UnServiceable", value: 5 }]

    useEffect(() => {
        if (assetData?.type === "on-Off") {
            if (assetData?.data === "On Wing") {
                getOnOffWingData({ per_page: 20, on_wing_status: "True" })
            } else {
                getOnOffWingData({ per_page: 20, on_wing_status: "False", off_wing_status: OnOffWingOption.filter((items) => items?.label === assetData?.data).map((item) => item?.value)[0] })
            }
        } else if (assetData?.type === "sv") {
            const formattedDate = convertToYearMonth(assetData?.data);
            getSVData({ sv_date_list: formattedDate, per_page: 20 })
        } else {
            getleaseStatusData({ ...filter, status: statusOption.filter((items) => items?.label === assetData?.data).map((item) => item?.value)[0], per_page: 50 }, 'pageloader');
        }
    }, []);

    const createSortHandler = (key, module) => {
        let sortQuery = { sort: key };
        if (sortInfo.sort === key) {
            sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
        } else {
            sortQuery = { ...sortQuery, sort_by: 'asc' }
        }
        setSortInfo(sortQuery)
        if (module === 'on-Off') {
            getOnOffWingData({ ...filter, ...sortQuery, page: 1, per_page: onOffWingData?.pagination?.per_page }, 'pageLoader');

        } else if (module === 'sv') {
            getSVData({ ...filter, ...sortQuery, page: 1, per_page: svData?.pagination?.per_page, sv_date_list: formattedDate }, 'pageLoader');

        } else {
            getleaseStatusData({ ...filter, ...sortQuery, page: 1, per_page: leaseStatusData?.pagination?.per_page }, 'pageLoader');
        }

    }

    const getleaseStatusData = (query) => {
        let queryParams = Object.assign({}, query);
        setLoading(true);
        globalGetService(`camo/smbc_dashboard/asset_list/`, { ...queryParams })
            .then(response => {
                if (checkApiStatus(response)) {
                    setleaseStatusData(response.data);
                    delete query.page
                    delete query.per_page
                    setFilter(query);
                } else {
                }
                setLoading(false);
            });
    }
    const getOnOffWingData = (query) => {
        let queryParams = Object.assign({}, query);
        setLoading(true);
        globalGetService(`camo/smbc_dashboard_on_off/list/`, { ...queryParams })
            .then(response => {
                if (checkApiStatus(response)) {
                    setOnOffWingData(response?.data);
                    setFilter(query);
                } else {
                }
                setLoading(false);
            });
    }
    const getSVData = (query) => {
        let queryParams = Object.assign({}, query);
        setLoading(true);
        globalGetService(`camo/smbc_dashboard/sv_date/`, { ...queryParams })
            .then(response => {
                if (checkApiStatus(response)) {
                    setsvData(response?.data);
                } else {

                }
                setLoading(false);
            });
    }

    const exportAssetsListing = (extension) => {
        setLoading(true);
        let queryParams = {}
        if (sortInfo?.sort === '' &&  sortInfo?.sort_by === '') {
            queryParams= Object.assign({}, filter);
        }else{
            queryParams= Object.assign({}, filter,sortInfo);
        }
        globalExportService(`camo/smbc_dashboard/asset_list/`, { ...queryParams, download: extension })
            .then(response => {
                setLoading(false);
                enqueueSnackbar(`${assetData?.data} Download successfull`, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                downloadFileType(response.data, `${assetData?.data}`, extension)
            });
    }
    const exportOnOffWing = (extension) => {
        let query = {}
        if (sortInfo?.sort === '' &&  sortInfo?.sort_by === '') {
            query= Object.assign({}, filter, delete filter?.per_page ,delete filter?.page );
        }else{
            query= Object.assign({}, filter,sortInfo, delete filter?.per_page ,delete filter?.page );
        }
        const queryParams = assetData?.data === "On Wing"
            ? { on_wing_status: "True",...query } : {...query, on_wing_status: "False", off_wing_status: OnOffWingOption.filter((item) => item?.label === assetData?.data).map((item) => item?.value)[0] };
        setLoading(true);
        globalExportService(`camo/smbc_dashboard_on_off/list/`, { ...queryParams, download: extension })
            .then(response => {
                setLoading(false);
                enqueueSnackbar(`${assetData?.data} Download successfull`, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                downloadFileType(response.data, `${assetData?.data}`, extension)
            });
    }
    const exportshopVisit = (extension) => {
        let queryParams = {}
        if (sortInfo?.sort === '' &&  sortInfo?.sort_by === '') {
            queryParams= Object.assign({}, filter);
        }else{
            queryParams= Object.assign({}, filter,sortInfo);
        }
        setLoading(true);
        globalExportService(`camo/smbc_dashboard/sv_date/`, {...queryParams, sv_date_list: convertToYearMonth(assetData?.data), download: extension })
            .then(response => {
                setLoading(false);
                enqueueSnackbar("Download of Shop Visits List successfull", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                downloadFileType(response.data, 'Shop Visits_list', extension)
            });
    }
    const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532)

    const rowClick = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        return (smbcInstance ? window.open(`${item?.id}/${item?.asset_type}/op_history/list`, '_blank') : window.open(`${item?.id}/${item?.asset_type}/ad/list`, '_blank'))
    }

    const formattedDate = convertToYearMonth(assetData?.data);

    const onCloseModal = () => {
        onClose()
    }
    return (
        <>
            <Dialog
                open={assetData?.type === "lease" && true}
                onClose={onClose}
                maxWidth='sm'
                className='dashboard-dialogue'
               
            >
                <DialogTitle style={{ backgroundColor: 'white', color: 'black', padding: '7px 8px 0px 20px' }}>
                    {assetData?.data}
                    <ul className='list-inline' style={{ float: 'right', margin: "5px" }}>
                        <li className='list-inline-item' style={{ marginLeft: '10px' }}>
                            <ExportMenu
                                exportReportFn={(file) => exportAssetsListing(file.extension)}
                                files={[{ title: 'EXCEL', extension: 'xls' }]}
                            />
                        </li>
                    </ul>
                </DialogTitle>
                <DialogContent dividers>
                    <Paper className='major-assemblies-list'>
                        <div style={{ height: !leaseStatusData?.results?.length ? 'auto' : `${window.innerHeight - 250}px`, overflow: 'auto' }}>
                            <Table className='mui-table-format' stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel active={sortInfo.sort === 'engine_type' ? true : false} direction={sortInfo.sort === 'engine_type' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('engine_type')} className='col-align-center'>
                                                Type and Model
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel active={sortInfo.sort === 'esn' ? true : false} direction={sortInfo.sort === 'esn' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('esn')} className='col-align-center'>
                                                ESN
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel active={sortInfo.sort === 'lessee' ? true : false} direction={sortInfo.sort === 'lessee' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('lessee')} className='col-align-center'>
                                                Lessee
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel active={sortInfo.sort === 'operator' ? true : false} direction={sortInfo.sort === 'operator' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('operator')} className='col-align-center'>
                                                Operator
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel active={sortInfo.sort === 'status' ? true : false} direction={sortInfo.sort === 'status' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('status')} className='col-align-center' >
                                                Lease  Status
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel active={sortInfo.sort === 'on_wing_msn' ? true : false} direction={sortInfo.sort === 'on_wing_msn' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('on_wing_msn')} className='col-align-center'>
                                                Current Status
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {leaseStatusData?.results.map((item, index) =>
                                        <TableRow style={{ cursor: "pointer" }} onClick={(e) => rowClick(e, item)}>
                                            <TableCell >
                                                <div style={{ width: '110px' }}>
                                                    {/* {item.asset_type === 1 && item?.aircraft_type?.name ? <p>{item?.aircraft_type?.name || ''} {item.model ? `/${item.model}` : ''}</p> : null} */}
                                                    {item?.engine_type ? <p>{item?.engine_type?.name || ''}</p> : null}
                                                    {item?.ownership_type?.value === 2 ?
                                                        <>
                                                            <Chip size="small" color="primary" label="Managed" />
                                                            {item?.owner_portfolio?.name ?
                                                                <Tooltip title={item?.owner_portfolio?.name || ''} arrow><InfoIcon style={{ fontSize: '13px', cursor: 'pointer' }} /></Tooltip> : null
                                                            }
                                                        </> : null
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell  >{item?.esn ? item?.esn : item?.asset_name || "--"}</TableCell>
                                            <TableCell>{item?.lessee?.name || "--"}</TableCell>
                                            <TableCell>{item?.operator?.name || "--"}</TableCell>
                                            <TableCell>
                                                <div style={{ width: '125px' }}>
                                                    {item?.status?.label ? <span className={`asset-status status-${item.status.value}`}>{item?.status?.label}</span> : null}
                                                    <div>
                                                        {item?.sub_status?.label ? <p className='para-text sub-status-bg'>{item?.sub_status?.label}</p> : null}
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>{item?.on_wing_status ? item?.on_wing_msn ? item?.on_wing_msn : "On Wing" :  "Off Wing"}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Pagination
                            pagination={leaseStatusData?.pagination}
                            onChangePage={(event, newPage) => getleaseStatusData({ ...filter, page: newPage + 1, per_page: leaseStatusData?.pagination?.per_page }, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getleaseStatusData({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                        />
                        {!leaseStatusData?.results?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title={'No records found'} /></div> : null}
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant='contained' color='primary'>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={assetData?.type === "on-Off" && true}
                // onClose={onClose}
                maxWidth='sm'
                className='dashboard-dialogue on-Off'
            >
                <DialogTitle style={{ backgroundColor: 'white', color: 'black', padding: '7px 8px 0px 20px' }}>
                    {assetData?.data}
                    <ul className='list-inline' style={{ float: 'right', margin: "5px" }}>
                        <li className='list-inline-item' style={{ marginLeft: '10px' }}>
                            <ExportMenu
                                exportReportFn={(file) => exportOnOffWing(file.extension)}
                                files={[{ title: 'EXCEL', extension: 'xls' }]}
                            />
                        </li>
                    </ul>
                </DialogTitle>
                <DialogContent dividers>
                    <Paper className='major-assemblies-list'>
                        <div style={{ height: !onOffWingData?.results?.length ? 'auto' : `${window.innerHeight - 250}px`, overflow: 'auto' }}>
                            <Table className='mui-table-format' stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        {assetData?.data === "On Wing" && <TableCell >
                                            <TableSortLabel  active={sortInfo.sort === 'on_wing_msn' ? true : false} direction={sortInfo.sort === 'on_wing_msn' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('on_wing_msn', 'on-Off')} className='col-align-center'>
                                                On Wing MSN
                                            </TableSortLabel> </TableCell>}
                                        {assetData?.data !== "On Wing" && <TableCell  >
                                            <TableSortLabel active={sortInfo.sort === 'off_wing_status' ? true : false} direction={sortInfo.sort === 'off_wing_status' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('off_wing_status', 'on-Off')} className='col-align-center'>
                                                Wing Status
                                            </TableSortLabel> </TableCell>}
                                        <TableCell  >
                                            <TableSortLabel active={sortInfo.sort === 'lessee' ? true : false} direction={sortInfo.sort === 'lessee' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('lessee', 'on-Off')} className='col-align-center'>
                                                Lessee
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell  >
                                            <TableSortLabel active={sortInfo.sort === 'operator' ? true : false} direction={sortInfo.sort === 'operator' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('operator', 'on-Off')} className='col-align-center'>
                                                Operator
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell >
                                            <TableSortLabel active={sortInfo.sort === 'esn' ? true : false} direction={sortInfo.sort === 'esn' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('esn', 'on-Off')} className='col-align-center'>
                                                ESN
                                            </TableSortLabel>
                                        </TableCell>
                                        {assetData?.data !== "On Wing" && <TableCell  width='230px'>
                                            <TableSortLabel active={sortInfo.sort === 'current_operating_thrust' ? true : false} direction={sortInfo.sort === 'current_operating_thrust' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('current_operating_thrust', 'on-Off')} className='col-align-center'>
                                                Current Operating Thrust
                                            </TableSortLabel>
                                        </TableCell>}
                                        <TableCell width='200px'>
                                            <TableSortLabel active={sortInfo.sort === 'last_utilization_updated' ? true : false} direction={sortInfo.sort === 'last_utilization_updated' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('last_utilization_updated', 'on-Off')} className='col-align-center'>
                                                Last Utilization Update
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell width='90px'>
                                            <TableSortLabel active={sortInfo.sort === 'status' ? true : false} direction={sortInfo.sort === 'status' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('status', 'on-Off')} className='col-align-center'>
                                                Status
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell width='80px'>
                                            <TableSortLabel active={sortInfo.sort === 'location' ? true : false} direction={sortInfo.sort === 'location' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('location', 'on-Off')} className='col-align-center'>
                                                Location
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {onOffWingData?.results.map((item, index) =>
                                        <TableRow style={{ cursor: "pointer" }} onClick={(e) => rowClick(e, item)}>
                                            {assetData?.data === "On Wing" && <TableCell >{item?.on_wing_msn || '--'}</TableCell>}
                                            {assetData?.data !== "On Wing" && <TableCell >{item?.off_wing_status?.label || '--'}</TableCell>}
                                            <TableCell  >{item?.lessee?.name || '--'}</TableCell>
                                            <TableCell  >{item?.operator?.name || '--'}</TableCell>
                                            <TableCell  >{item?.esn || '--'}</TableCell>
                                            {assetData?.data !== "On Wing" && <TableCell >{item?.current_operating_thrust?.name || '--'}</TableCell>}
                                            <TableCell  >{item?.last_utilization_updated ? moment(item.last_utilization_updated).format(dayMonthDateFormat) : '--'}</TableCell>
                                            <TableCell  >{item?.status?.label || '--'}</TableCell>
                                            <TableCell  >{item?.location || '--'}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Pagination
                            pagination={onOffWingData?.pagination}
                            onChangePage={(event, newPage) => getOnOffWingData({ ...filter, page: newPage + 1, per_page: onOffWingData?.pagination?.per_page }, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getOnOffWingData({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                        />
                        {!onOffWingData?.results?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title={'No records found'} /></div> : null}
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseModal} variant='contained' color='primary'>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={assetData?.type === "sv" && true}
                onClose={onClose}
                maxWidth='sm'
                className='dashboard-dialogue'
            >
                <DialogTitle style={{ backgroundColor: 'white', color: 'black', padding: '7px 8px 5px 20px' }}>
                    Shop Visits
                    <ul className='list-inline' style={{ float: 'right', margin: "5px" }}>
                        <li className='list-inline-item' style={{ marginLeft: '10px' }}>
                            <ExportMenu
                                exportReportFn={(file) => exportshopVisit(file.extension)}
                                files={[{ title: 'EXCEL', extension: 'xls' }]}
                            />
                        </li>
                    </ul>
                </DialogTitle>
                <DialogContent dividers>
                    <Paper className='major-assemblies-list'>
                        <div style={{ height: !svData?.results?.length ? 'auto' : `${window.innerHeight - 250}px`, overflow: 'auto' }}>
                            <Table className='mui-table-format' stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel active={sortInfo.sort === 'engine_type_name' ? true : false} direction={sortInfo.sort === 'engine_type_name' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('engine_type_name', 'sv')} className='col-align-center'>
                                                Engine Type
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel active={sortInfo.sort === 'esn' ? true : false} direction={sortInfo.sort === 'esn' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('esn', 'sv')} className='col-align-center'>
                                                ESN
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell  >
                                            <TableSortLabel active={sortInfo.sort === 'lessee' ? true : false} direction={sortInfo.sort === 'lessee' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('lessee', 'sv')} className='col-align-center'>
                                                Lessee
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell  >
                                            <TableSortLabel active={sortInfo.sort === 'operator' ? true : false} direction={sortInfo.sort === 'operator' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('operator', 'sv')} className='col-align-center'>
                                                Operator
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel active={sortInfo.sort === 'sv_forecast_date' ? true : false} direction={sortInfo.sort === 'sv_forecast_date' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('sv_forecast_date', 'sv')} className='col-align-center'>
                                                SV Forecast Date
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {svData?.results.map((item, index) =>
                                        <TableRow style={{ cursor: "pointer" }} onClick={(e) => rowClick(e, item)}>
                                            <TableCell  >{item?.engine_type_name || '--'}</TableCell>
                                            <TableCell  >{item?.esn || '--'}</TableCell>
                                            <TableCell  >{item?.lessee || '--'}</TableCell>
                                            <TableCell  >{item?.operator || '--'}</TableCell>
                                            <TableCell  >{item?.sv_forecast_date ? moment(item.sv_forecast_date).format(dayMonthDateFormat) : '--'}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Pagination
                            pagination={svData?.pagination}
                            onChangePage={(event, newPage) => getSVData({ ...filter, page: newPage + 1, per_page: svData?.pagination?.per_page, sv_date_list: formattedDate }, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getSVData({ ...filter, page: 1, per_page: event.target.value, sv_date_list: formattedDate }, 'pageLoader')}
                        />
                        {!svData?.results?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title={'No records found'} /></div> : null}
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant='contained' color='primary'>Close</Button>
                </DialogActions>
            </Dialog>
            {isLoading ? <PageLoader /> : null}
        </>
    )
}

export default UnderlyingChartData