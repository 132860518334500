import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { Box, Grid, IconButton } from "@material-ui/core";
import SbSearchBar from "../components/SbSearchBar";
import SbTable from "../components/SbTable";
import { convertFilterObject, removeEmptyKey, permissionCheckFnforCamo, } from "../../../../utils";
import { assetFilterOps, SbTableHead,SmbcTableHead } from "./SbTableHead";
import { EmptyCollection, PageLoader, AccessDenied, } from "../../../../shared_elements";
import SbList from "../components/SbList";
import { deleteSbAssetDetailRow, fillSbEditFormApi, getSbAircraftDetailApi, getSbApi, importSbFileApi, UpdateSbTaskFormApi, uploadSbFileApi, exportSbAssetDetailListApi, DownloadAllApi,exportMasterApi,bulkDelete } from "../apiService";
import BasicDetailHeader from "../../BasicDetailHeader";
import { Link } from "react-router-dom";
import RefreshIcon from "@material-ui/icons/Refresh";
import PieChartOutlinedIcon from "@material-ui/icons/PieChartOutlined";
import { Tooltip } from "@material-ui/core";
import { assetType } from "../../../../constants";
import FlightIcon from '@material-ui/icons/Flight';
import config from '../../../../config';
import { getLocalStorageInfo } from "../../../../utils";
import DownloadXLSAssetDetail from "../../../../shared_elements/DownloadXLSAssetDetail";
import { trackActivity } from '../../../../utils/mixpanel';
class SbComponents extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addFormDialog: false,
			sidebarOpen: true,
			tabIndex: 0,
			pageLoader: false,
			sort: "",
			sort_by: "",
			assetsDetail: {
				list: [],
				pagination: {},
				asset_count: {},
				new_task_last_used_folder_uuid: '',
				last_used_folder_uuid_link_document: '',
				checkbox_last_used_folder_uuid: '',
				checkbox_last_used_folder_name: ''
			},
			headerData: {},
			headerTsn: "",
			headerCsn: "",
			currentRecordPerPage: 50,
			uuid: "",
			slug:"",
			bulkOperation: { modal: false, ids: [], title: '', type: '' }
		};
		this.getSbApi = getSbApi.bind(this);
		this.openAddForm = this.openAddForm.bind(this);
		this.closeAddForm = this.closeAddForm.bind(this);
		this.toggleSidebar = this.toggleSidebar.bind(this);
		this.handleDeleteRow = deleteSbAssetDetailRow.bind(this); //done
		this.handleEditRow = fillSbEditFormApi.bind(this);
		this.getSbAircraftDetailApi = getSbAircraftDetailApi.bind(this);
		this.UpdateSbTaskFormApi = UpdateSbTaskFormApi.bind(this); //done
		this.uploadSbFileApi = uploadSbFileApi.bind(this); //done
		this.importSbFileApi = importSbFileApi.bind(this); //done
		this.downloadAllApi = DownloadAllApi.bind(this);
		this.exportSbAssetDetailListApi = exportSbAssetDetailListApi.bind(this);
		this.exportMasterApi = exportMasterApi.bind(this);
		this.bulkDelete = bulkDelete.bind(this);
	}

	componentDidMount() {
		this.mainHeaderData(this.props);
		this.getData();
		this.pullData();
		trackActivity('CAMO', {
			event_type: 'Page Visited',
			page_title: 'Service Bulletins(SB)',
		  })
	}
	pullData = (slug,tsn, csn) => {
		this.setState({ slug: slug });
		this.setState({ headerTsn: tsn });
		this.setState({ headerCsn: csn });
	};
	
	uuidData = (uuid) => {
		this.setState({ uuid: uuid });
	}

	getData(item) {
		this.getSbApi({ per_page: this.state.currentRecordPerPage,sort: "sb_ref_number",
		sort_by: "asc", }, "skeletonLoader", this.props, item ? item : null);
	}

	mainHeaderData = (props) => {
		getSbAircraftDetailApi(props).then((response) => {
			const data = response.data.data;
			this.setState({ headerData: data });
		});
	};

	openAddForm() {
		this.setState({ addFormDialog: true });
	}
	closeAddForm() {
		this.setState({ addFormDialog: false });
	}

	toggleSidebar() {
		this.setState({ sidebarOpen: !this.state.sidebarOpen });
	}
	createSortHandler = (sortField) => {
		const { sort, sort_by, filter, tabIndex, assetsDetail } = this.state;
		this.setState({
			sort: sortField,
			sort_by:
				sortField === sort ? (sort_by === "asc" ? "desc" : "asc") : "desc",
		})
		if (sortField === sort) {
			if (sort_by === "asc") {
				this.getSbApi({ ...filter, sort: sortField, sort_by: "desc", per_page: assetsDetail.pagination.per_page, asset_type: tabIndex, }, "pageLoader", this.props);
			} else {
				this.getSbApi({ ...filter, sort: sortField, sort_by: "asc", per_page: assetsDetail.pagination.per_page, asset_type: tabIndex, }, "pageLoader", this.props);
			}
		} else {
			this.getSbApi({ ...filter, sort: sortField, sort_by: "asc", per_page: assetsDetail.pagination.per_page, asset_type: tabIndex, }, "pageLoader", this.props);
		}
	};

	submitFilter = (filterOptions) => {
		const { tabIndex, applyingFilter } = this.state;
		this.getSbApi({ ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions), asset_type: tabIndex, }, "pageLoader", this.props);
	};

	searchResults = (item) => {
		const newURL = window.location?.origin + window.location?.pathname
		 if (item) {
			this.getData(item);
			setTimeout(()=>{
				window.history.replaceState({ ...window.history.state, as: newURL, url: newURL }, '', newURL);

			},1000)
		} else {
				window.history.replaceState({ ...window.history.state, as: newURL, url: newURL }, '', newURL);
			    this.getData(item);
			
		}
	};

	updateFormData = async (updateId, updatedData, props,close,formClose) => {
		await this.UpdateSbTaskFormApi(updateId, updatedData, props, this.state.currentRecordPerPage,close,formClose);
	};

	fileUploadData = async (uploadId, formdata, props, haveAttachments) => {
		await this.uploadSbFileApi(uploadId, formdata, props, this.state.currentRecordPerPage, haveAttachments);
	};

	importFile = (formData, props, isXLSX) => {
		this.importSbFileApi(formData, props, isXLSX, this.state.currentRecordPerPage);
	};

	exportAssetDetailListFunc = (props) => {
		this.exportSbAssetDetailListApi(props);
	};

	downloadAllFilesApi = (props) => {
		this.downloadAllApi(props);
	};

	refreshComp = () => {
		this.getData();
	};

	toggleBulkOps = async (flag, type, item) => {
        const { bulkOperation, assetsDetail } = this.state;   
        try {
            if (type === 'bulk') {
                const response = await this.getSbApi(
                    { per_page: assetsDetail?.pagination?.total},
                    "skeletonLoader",
                    this.props,
                    null
                );
                this.setState({
                    bulkOperation: { ...bulkOperation, type: 'bulk', ids: flag ? response?.data?.results : [] }
                });
            } else {
                this.setState({
                    bulkOperation: {
                        ...bulkOperation,
                        type: 'single',
                        ids: flag ? [...bulkOperation.ids, item] : bulkOperation.ids.filter(optionItem => optionItem.id !== item.id)
                    }
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

	render() {
		const { tabIndex, applyingFilter, sort, sort_by, assetsDetail, pageLoader, uuid ,slug,bulkOperation} = this.state;
		const { aircraftTypes, engineTypes, lessees } = this.props;
      
		let filterOptions = assetFilterOps;
		if (tabIndex === 0 || tabIndex === 1) {
			filterOptions = {
				...filterOptions,
				aircraft_type: {
					...filterOptions.aircraft_type,
					options: aircraftTypes,
				},
			};
			if (tabIndex === 1) {
				delete filterOptions.engine_type;
			}
		}
		if (tabIndex === 0 || tabIndex === 2) {
			filterOptions = {
				...filterOptions,
				engine_type: {
					...filterOptions.engine_type,
					options: engineTypes,
				},
			};
			if (tabIndex === 2) {
				delete filterOptions.aircraft_type;
			}
		}

		filterOptions = {
			...filterOptions,
			lessee: {
				...filterOptions.lessee,
				options: lessees,
			},
		};
		const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)

		return (
			<section className="amp-listing-section">
				{pageLoader ? <PageLoader /> : null}
				<Grid className="mt-0" spacing={3} container>
					<Grid className="pt-0 pr-0" xs={12} item>
						<Box display="flex" alignItems="center">
							<Box flexGrow={1}>
								<ul className='list-inline'>
									<li className='list-inline-item'> <h3 className="module-heading" style={{marginTop:'6px'}}>SB Components</h3></li>
									{smbcInstance ? null :<li className='list-inline-item'> <a href={`${config.domain.subDomian}technical-specification/${assetType[this.props?.match?.params?.assetType]}/${slug}`} target="_blank"> <h3 className="module-heading" style={{marginTop:'6px'}}><FlightIcon fontSize='small' color='primary' style={{ verticalAlign: "bottom", marginLeft: "30px" }} />Technical Specification</h3></a></li>}
									{smbcInstance &&<li className='list-inline-item export-list' > <DownloadXLSAssetDetail  files={{ title: "Master Export", extension: "xls", key: "" }} exportReportFn={(file) => this.exportMasterApi({ download: file.extension}, "pageLoader",this.props)} style={{color:"#bd10e0"}} /> </li>}
								
								</ul>
							</Box>
							<Box className="mr-18">
								{smbcInstance ? null :<Link to="/dashboard?sb_Module">
									<Tooltip title="Analytics">
										<IconButton className="p-5px" color="primary">
											<PieChartOutlinedIcon />
										</IconButton>
									</Tooltip>
								</Link>}
								<Tooltip title="Refresh">
									<IconButton
										className="p-5px"
										color="primary"
										onClick={this.refreshComp}
									>
										<RefreshIcon />
									</IconButton>
								</Tooltip>
							</Box>
						</Box>
						<BasicDetailHeader props={this.props} func={this.pullData} uuid={this.uuidData} module='sb' />
						{permissionCheckFnforCamo({
							primaryKey: "sb_status",
							keyIndex: "R",
						}) ? (
							<div className="custom-border">
								<SbSearchBar
									headerData={this.state.headerData}
									currentRecordPerPage={this.state.currentRecordPerPage}
									applyingFilter={applyingFilter}
									filterOptions={filterOptions}
									tabIndex={tabIndex}
									pageLoader={"pageLoader"}
									props={this.props}
									uuid={uuid}
									headerTsn={this.state.headerTsn}
									headerCsn={this.state.headerCsn}
									importFile={this.importFile}
									getResponseBack={this.refreshComp}
									fileUploadData={this.fileUploadData}
									searchData={(search) => this.searchResults(search)}
									exportReportFn={(file) =>
										this.exportAssetDetailListFunc(
											{
												download: file.extension,
												...{
													...convertFilterObject(
														removeEmptyKey(applyingFilter),
														filterOptions
													),
													asset_type: tabIndex,
												},
											},
											"pageLoader"
										)
									}
								/>
								<SbTable
									currentRecordPerPage={this.state.currentRecordPerPage}
									heads={smbcInstance ? SmbcTableHead : SbTableHead}
									sort={sort}
									sort_by={sort_by}
									bulkDeletefn={()=>this.bulkDelete(this.props,bulkOperation, assetsDetail.pagination.per_page,)}
                                    bulkOperation={bulkOperation}
                                    bulkFlag={assetsDetail?.list?.length === bulkOperation?.ids?.length && assetsDetail?.list?.length > 0}
                                    toggleBulkOps={(flag) => this.toggleBulkOps(flag, 'bulk')}
									data={assetsDetail.list.map((item, index) => (
										<SbList
										    bulkOperation={bulkOperation}
										    toggleBulkOps={(flag) => this.toggleBulkOps(flag, 'single', item)}
											headerData={this.state.headerData}
											currentRecordPerPage={this.state.currentRecordPerPage}
											key={index}
											item={item}
											index={index}
											uuid={uuid}
											assetsDetail={assetsDetail}
											getResponseBack={this.refreshComp}
											last_used_folder_uuid={assetsDetail.new_task_last_used_folder_uuid}
											last_used_folder_uuid_link_document={assetsDetail.last_used_folder_uuid_link_document}
											checkbox_last_used_folder_uuid={assetsDetail.checkbox_last_used_folder_uuid}
											checkbox_last_used_folder_name={assetsDetail.checkbox_last_used_folder_name}
											headerTsn={this.state.headerTsn}
											headerCsn={this.state.headerCsn}
											handleDeleteRow={(data) =>
												this.handleDeleteRow(
													item,
													this.props,
													this.state.currentRecordPerPage
												)
											}
											updateFormData={this.updateFormData}
											handleEditRow={(data) =>
												this.handleEditRow(item, this.props)
											}
											props={this.props}
											fileUploadData={this.fileUploadData}
											downloadAllApi={(file) =>
												this.downloadAllFilesApi(
													{
														download: file.extension,
														...{
															...convertFilterObject(
																removeEmptyKey(applyingFilter),
																filterOptions
															),
															asset_type: tabIndex,
															item,
														},
													},

													"pageLoader"
												)
											}
										/>
									))}
									createSortHandler={this.createSortHandler}
									pagination={assetsDetail.pagination}
									onChangePage={(event, newPage) =>
										this.getSbApi(
											{
												...convertFilterObject(
													removeEmptyKey({
														...applyingFilter,
														asset_type: this.state.tabIndex,
														per_page: assetsDetail.pagination.per_page,
														page: newPage + 1,
													}),
													filterOptions
												),
											},
											"pageLoader",
											this.props
										)
									}
									onChangeRowsPerPage={(event) =>
										this.setState(
											{ currentRecordPerPage: event.target.value },
											() => {
												this.getSbApi(
													{
														...convertFilterObject(
															removeEmptyKey({
																...applyingFilter,
																asset_type: this.state.tabIndex,
																per_page: this.state.currentRecordPerPage,
															}),
															filterOptions
														),
													},
													"pageLoader",
													this.props
												);
											}
										)
									}
									noRecord={
										assetsDetail.list.length ? null : !this.state.pageLoader ? (
											<EmptyCollection title="No Records Found" />
										) : null
									}
								/>
							</div>
						) : (
							<AccessDenied />
						)}
					</Grid>
				</Grid>
			</section>
		);
	}
}
export default withSnackbar(SbComponents);
