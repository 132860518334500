import FleetAdListing from "./FleetAdListing";
import FleetAdTableHeader from "./FleetAdTableHeader";
import AddEditFleetAD from "./AddEditFleetAD";
import FleetSbListing from "./FleetSbListing";
import FleetSbTableHeader from "./FleetSbTableHeader";
import AddEditFleetSB from "./AddEditFleetSB";
import UploadAttachements from "./UploadAttachements";
import AddEditConfigure from "./AddEditConfigure";
import ConfigureListing from "./ConfigureListing";
import ConfigureTableHeader from "./ConfigureTableHeader";
import ReportsTab from "./ReportsTab";
import ServiceableEngine from "./ServiceableEngine";
import UnServiceableEngine from "./UnServiceableEngine";
import AddEditAlerts from "./AddEditAlerts";
import RevisionList from "./RevisionList";

export{
    FleetAdListing,
    FleetAdTableHeader,
    AddEditFleetAD,
    FleetSbListing,
    FleetSbTableHeader,
    AddEditFleetSB,
    UploadAttachements,
    AddEditConfigure,
    ConfigureListing,
    ConfigureTableHeader,
    ReportsTab,
    ServiceableEngine,
    UnServiceableEngine,
    AddEditAlerts,
    RevisionList
}
export const fleetSbData={
    asset_type_id:null,
    ata_chapter:null,
    engine_family:{},
    certificate_of_conformity:null,
    description:null,
    effectivity:null,
    effectivity_date:null,
    release_certificate:null,
    remark:null,
    revision:null,
    revision_date:null,
    sb_applicability:null,
    sb_category:null,
    sb_compliance_csn:null,
    sb_compliance_date:null,
    sb_compliance_dfp:null,
    sb_compliance_status:null,
    sb_compliance_tsn:null,
    sb_issued_by:null,
    sb_ref_number:null,
    subject:null,
    type:null
}
export const configDataObj = {
    revision_number: null,
    engine_family: null,
    engine_type: null,
    disclemer_message:"LLP CH.05 Life Limit is in accordance with ESM Revision"
}
export const notificationPreferences = [
    { label: 'Daily', value:  1 },
    { label: 'Weekly', value: 2 },
    { label: 'Monthly', value: 3 }
];
let days=[]
for ( let i=1; i<=31; i++) {
    days.push({ label: i.toString(), value: i });
}
export const notificationDays = days;

const febdays=[]
for ( let i=1; i<=28; i++) {
    febdays.push({ label: i.toString(), value: i });
}
export const notificationFebDays = febdays;

const thirtydays=[]
for ( let i=1; i<=30; i++) {
    thirtydays.push({ label: i.toString(), value: i });
}
export const notificationthirtyDays = thirtydays;

export const notificationWeekdays = [
    { label: 'Monday', value: '0' },
    { label: 'Tuesday', value: 1 },
    { label: 'Wednesday', value: 2 },
    { label: 'Thursday', value: 3 },
    { label: 'Friday', value: 4 },
    { label: 'Saturday', value: 5 },
    { label: 'Sunday', value: 6 }
];
